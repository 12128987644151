import request from '../request'

//库存列表
export const stock_index_request = p => {
    return request({
        method:'GET',
        url:'stock/index',
        params: p
    })
}

//获取库存详情
export const stock_details_request = id => {
    return request({
        method:'GET',
        url:'stock/details',
        params: {
            id
        }
    })
}

//库存统计
export const stock_count_request = p => {
    return request({
        method:'GET',
        url:'stock/count',
        params: p
    })
}